
import "../../styles/experience/_experience.scss";
import { useState } from "react";
import headerBg from '../../img/experience-bg.png';

import { useData } from "../../api/config/fetch";

const Experience = () => {
    const { data, error } = useData('experience');
    if (error) {
        console.log(error);
    }
    const [activeTabIndex, setActiveTabIndex] = useState(null);

    const activeTabClick = (index) => {
        setActiveTabIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        data !== undefined && 
        <div 
            className="container experience"
            id="skills"
        >
            <img src={headerBg} className="experience__bg"/>

            <div className="experience__title">
                <h2>Experience</h2>
            </div>
            <div className="experience__table">
                { data?.map((exp, index) => {
                    return (
                        <div
                            key={index} 
                            className={`experience__table__card ${activeTabIndex === index ? 'active' : ''}`}
                            onClick={() => activeTabClick(index)}
                            >
                            <div className="experience__table__card-head">
                                <p>
                                    { exp.position } @ { exp.company }
                                </p>
                                <p>
                                    { exp.start_date } - { exp.end_date } 
                                </p>
                            </div>
                            <div className="experience__table__card-details">
                                <p>
                                    { exp.city } -  
                                    <a target="_blank" href={ exp.company_url }> 
                                        <img src={ exp.company_logo }></img>
                                    </a> 
                                </p>
                                <p>
                                    { exp.technologies } 
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Experience;