import "../../styles/navbar/_navbar.scss";
import { useState, useEffect } from "react";
import { useData } from "../../api/config/fetch";
import Logo from "../header/Logo";

const useIsMobileScreen = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return windowWidth < 768 ? true : false;
}
const Navbar = () => {
    const { data } = useData('navbar');
    const isCurrentMobileScreen = useIsMobileScreen();
    const [isMenuActive, setIsMenuActive] = useState(false);

    const menuBtn = () => {
        setIsMenuActive(!isMenuActive);
    };

    return (
        data !== undefined && 
        <nav className="container navbar">
            <Logo className="navbar__logo"/>
            <div 
                className={`navbar-button ${isCurrentMobileScreen ? 'active' : ''} ${isMenuActive ? 'clicked' : ''}`}
                onClick={menuBtn}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={`navbar__menu ${isCurrentMobileScreen && isMenuActive ? 'active' : ''}`}>
                { data?.map((nav) => {
                    return (
                        <a href={nav.item_url}>
                            {nav.item_name}
                        </a>
                    )
                })}
            </div>
        </nav>
    )
}

export default Navbar;