import "../../styles/projects/_projects.scss";
import projectsBg from '../../img/bg.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useData } from "../../api/config/fetch";

import 'swiper/css'; 
import { Autoplay } from 'swiper/modules';

const Projects = () => {
    const { data, error } = useData('projects');
    if (error) {
        console.log(error);
    }

    return (
        data !== undefined && 
        <div 
            className="container projects"
            id="projects"
        >
            <div className="projects__title">
                <h2>Selected Projects</h2>
            </div>
            <div className="projects__list">
                <Swiper
                breakpoints={{
                    550: {
                        slidesPerView: 1.5
                    },
                    991: {
                        slidesPerView: 2.5,
                    },
                    1200: {
                        slidesPerView: 3.2
                    }
                }}
                slidesPerView={1.25}
                spaceBetween={30}
                loop={true}
                  slidesPerColumnFill="row"
                autoplay={{
                    dealy: 2500
                }}
                modules={[Autoplay]}
                >
                { data?.map((project) => {
                    return (
                        <SwiperSlide 
                            className="projects__list__card"
                        >
                            <img className="projects__list__card-thumbnail" src={project.thumbnail}/>
                            <p>{project.name}</p>
                            <div className="projects__list__card--back">
                                <img src={project.logo}/>
                                <p>
                                    Click to open the project
                                </p>
                            </div>
                        </SwiperSlide>
                    )
                })}
                </Swiper>
            </div>
        </div>
    )
}

export default Projects;