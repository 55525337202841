import "../../styles/header/_header.scss";
import headerBg from '../../img/header-bg.png';
import { useData } from "../../api/config/fetch";
const HeaderContent = () => {
    const { data, error } = useData('header');
    if (error) {
        console.log(error);
    }

    return (
        data !== undefined && 
        <div className="header-bg">
            <img src={headerBg} className="header__bg"/>
            <img src={headerBg} className="header__bg header__bg--second"/>
            <div className="container header">
                <div 
                    className="header__content">
                    {data.title && (
                        <div className="header__content__title"
                            dangerouslySetInnerHTML={{ __html: data.title }}>
                        </div>
                    )} 
                    {data.text && (
                        <div
                            className="header__content__text"
                            dangerouslySetInnerHTML={{ __html: data.text }}>
                        </div>
                    )}
                </div>
                <div 
                className="header__image">
                    { data.image && (
                        <img src={ data.image }/>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HeaderContent;