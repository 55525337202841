import '../../styles/footer/_footer.scss';

const Footer = () => {
    return (
        <footer
            className="footer container">
            <p>
            created with ❤️ using 
            <a href="https://react.dev/" target="_blank">
                React
            </a>
            & 
            <a href="https://developer.wordpress.org/rest-api/" target="_blank">
                WordPress Rest API
            </a>
            </p>
        </footer>
    );
}

export default Footer;