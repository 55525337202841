import React, { useState, useEffect } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";
import './App.scss';
import Navbar from './components/navbar/Navbar';
import HeaderContent from './components/header/HeaderContent';
import Footer from './components/footer/Footer';
import Skills from './components/skills/Skills';
import Experience from './components/experience/Experience';
import Projects from './components/projects/Projects';
import { useData } from "./api/config/fetch"


const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const handlePageLoad = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };

    window.addEventListener('load', handlePageLoad);

    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, []);

  const { data } = useData('page_status');

  if (isLoading) {
    return (
      <>
        <div className="loader">
          <PropagateLoader
            color="#4edffe"
          />
        </div>
      </>
    ) 
  }


  if (data === true) {
    return (
      <>
        <Navbar/>
        <HeaderContent/>
        <Skills/>
        <Experience/>
        <Projects/>
        <Footer/>
      </>
    )
  }
  else {
    return (
      <div className='container mainteance' dangerouslySetInnerHTML={{__html: data}}></div>
    )
  }
}

export default App;