import { useEffect, useState } from "react";
import { api_settings } from "../main";


export const useData = (endpoint) => {
    const api_url = `${api_settings.API_URL}/${endpoint}`;
    const [state, setState] = useState();
    const [error, setError] = useState(null); 

    useEffect(() => {
        const dataFetch = async () => {
            try {
                const response = await fetch(api_url);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                else {
                    const data = await response.json();
                    setState(data);
                }
                
            }
            catch (err) {
                console.log(err);
                setError(err);
            }
        };
        dataFetch();
    }, [api_url]);

    return {
        data: state,
        error: error
    }
}