import { useData } from "../../api/config/fetch";

const Logo = () => {
    const { data, error } = useData('logo');
    if (error) {
        console.log(error);
    }

    return (
        data !== undefined && 
        <a href="#">
            <img className="navbar__logo" src={data} alt="JBrambor - Logo"/>
        </a>
    )
}

export default Logo;