import "../../styles/skills/_skills.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useData } from "../../api/config/fetch";

import 'swiper/css'; 
import { Autoplay } from 'swiper/modules';

const Skills = () => {
    const { data, error } = useData('skills');
    if (error) {
        console.log(error);
    }
    return (
        data !== undefined && 
        <div 
            className="container skills"
            id="skills"
        >
            <div className="skills__title">
                <h2>Skills</h2>
            </div>
            <div className="skills__list">
                <Swiper
                slidesPerView={'auto'} 
                loop={true}
                  slidesPerColumnFill="row"
                autoplay={{
                    dealy: 1000
                }}
                modules={[Autoplay]}
            >
                { data?.map((skill) => {
                    return (
                        <SwiperSlide 
                            className="skills__list__card"
                        >
                            <img src={skill.img}/>
                            <p>{skill.title}</p>
                        </SwiperSlide>
                    )
                })}
                </Swiper>
            </div>
        </div>
    )
}

export default Skills;